<template>
  <article class="contact fill-height">
    <header>
      <span class="display-2 font-weight-light">Contact</span>
      <hr class="line-separator">
    </header>
    <main class="main-contact">
      <div class="contact-info">
          <p class="contact-label">
            Contact Info <br>
            <a href="mailto:info@vitaverse.io">info@vitaverse.io</a>
          </p>
          <p></p>
          <p class="contact-address">
            1486 Colorado Blvd
            <br>
              Los Angeles, CA
            <br>
            90041
          </p>
      </div>

      <div class="contact-form" v-if="isSubscribed">
        <div class="form-title-contact">Thank you for contacting us!</div>
      </div>
      <div class="contact-form" v-else>
        <v-card-text>
          <div>
            <v-col class="contact-input">
              <v-text-field
                label="Name"
                v-model="form.name"
                :rules="nameRules"
                ref="username"
                hide-details="auto"
                maxlength="100"
                dense
                filled
                height="40px"
                required
              />
            </v-col>
            <v-col class="contact-input">
              <v-text-field
                label="Email"
                :rules="emailRules"
                ref="email"
                v-model="form.email"
                maxlength="100"
                hide-details="auto"
                dense
                filled
                height="40px"
                required
              />
            </v-col>
            <v-col class="contact-input">
              <v-textarea
                label="Message"
                :rules="messageRules"
                ref="message"
                v-model="form.message"
                hide-details="auto"
                maxlength="300"
                dense
                filled
                required
              />
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="submit-button"
            :disabled="submitDisabled"
            :loading="isLoading"
            width="100%"
            height="60px"
            @click="subscribe"
          >
            Submit
          </v-btn>
        </v-card-actions>
        <v-overlay :absolute="absolute" :value="overlay"/>
        <br>
        <v-progress-linear
          v-if="overlay"
          indeterminate
          color="teal accent-4"
        />
      </div>
    </main>
    <v-alert
      v-model="alert"
      :color="alertColor"
      dark
      :type="alertType"
      class="alert-notify"
    >
      <div>
        {{ alertText }}
      </div>
      <template v-slot:close v-if="alertDismissible">
        <v-icon @click="alert = false" style="font-size: 18px;margin-left: 15px;">mdi-close</v-icon>
      </template>
    </v-alert>
  </article>
</template>

<script>
import request from "@/plugins/request";

export default {
  name: 'CONTACT',
  props: ['toggle'],
  data() {
    return {
      formState: 'CONTACT',
      reveal: false,
      absolute: true,
      overlay: false,
      multiLine: true,
      snackbar: false,
      snackBarState: 'success',
      nameRules: [
        value => !/[^a-zA-Z]/g.test(value) ||  "Username must only contain letters",
        value => (value && value.length < 100) || 'Max 100 characters',
      ],
      messageRules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
        value => (value && value.length < 300) || 'Max 300 characters',
      ],
      emailRules: [
        value => !!value || 'Required.',
        value => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value) || 'E-mail must be valid',
        value => (value && value.length < 100) || 'Max 100 characters',
      ],
      form: {
        name: '',
        email: '',
        message: ''
      },
      isLoading: false,
      isSubscribed: false,
      alert: false,
      alertType: 'success',
      alertText: '',
      alertDismissible: false,
      alertTimeout: null
    }
  },
  computed: {
    alertColor() {
      let color = 'green';
      switch (this.alertType) {
        case 'success':
          color = 'green';
          break;
        case 'error':
          color = 'red';
          break;
        default:
          color = 'green';
      }

      return color;
    },

    submitDisabled() {
      return this.form.name.length === 0 ||
          this.form.email.length === 0 ||
          this.form.message.length === 0 ||
          !this.$refs.username?.validate() ||
          !this.$refs.email?.validate() ||
          !this.$refs.message?.validate();
    },
  },
  methods: {
    showAlert(text, type) {
      if (this.alertTimeout) clearTimeout(this.alertTimeout);

      this.alertDismissible = type !== 'success';
      this.alertType = type;
      this.alertText = text;
      this.alert = true;
      if (type == 'success') {
        this.alertTimeout = setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },

    async subscribe() {
      this.isLoading = true;

      let body = {
        email: this.form.email,
        name: this.form?.name || "",
        message: this.form?.message || "",
      };

      try {
        let response = await request({
          method: 'POST',
          url: '/profiles/api/v1/Profile/SubscribeEmail',
          body: JSON.stringify(body)
        });

        if (response?.data?.result == 'success') {
          this.showAlert('Thank you for contacting us!', 'success');
          this.isSubscribed = true;
        }
        else {
          this.showAlert(response.data?.msg || 'Something went wrong. Please try again later.', 'error');
        }
      } catch (err) {
        console.log('request err - ', err);
        this.showAlert('Something went wrong. Please try again later.', 'error');
      }

      this.isLoading = false;
    },
  }

}
</script>
<style>
.form-title-contact {
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 15px;
  font-weight: 500;
  padding-bottom: 70px;
  max-width: 400px;
  text-align: center;
}

.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 10px;
}

.v-textarea.v-text-field--enclosed textarea {
  margin-top: 10px;
}

</style>
<style lang="scss" scoped>
header{
  margin-bottom:50px;
  padding-bottom:30px;
}
.contact-form{
  min-width:400px!important;
}

.contact-info{
  min-width:250px;
  margin-left:0;
  margin-right:0;
  font-size: 18px;

  .contact-label{
    font-size: 18px;
    letter-spacing: 3px;
    font-weight: 700;
    a{
      font-weight: 300!important;;
      color:white;
      text-decoration: none;
    }
  }
  .contact-address{
    letter-spacing: 2px;
    font-weight: 300!important;
  }
}

.contact-input{
  padding:5px 10px!important;
  min-width:400px;
  box-sizing: border-box;
}
.v-textarea .v-label{
  top:20px!important;
}

.v-card__actions{
    max-width: 380px;
    margin: auto;
    .v-btn.v-btn--has-bg {
      background-color: #FF3C11;
        height: 60px!important;
        width: 100%;
        span{
          font-size: 14px!important;
          font-weight: 700!important;
        }
    }
}
.contact {
  display: flex;
  flex-flow: column nowrap;
  padding-top:50px;
  /* justify-content: center; */
  align-items: center;

  width: 100%;

  height: calc(100% -  20px);
}

.contact main{
    display: flex;
}

.contact header, .contact main {
  max-width: 100em;
  padding:0 3em;
}

.contact header {
    padding: 0;
    margin-bottom: 80px!important;
}

#app .contact header > span {
  font-weight: 500!important;
  font-size: 65px !important;
  line-height: 1;
  letter-spacing: 2px!important;
  color: #FFFFFF;
  margin-bottom: 10px;
  text-transform: uppercase;
}

@media screen and(max-width:850px) {
    .contact-form{
      min-width:100px!important;
    }
    .contact-input{
      min-width:100px;
    }
      .main-contact{
        flex-direction: column;
        .contact-info{
          text-align: center;
        }
        padding: 0!important;
        width:100%;
      }
      .contact{
        padding-top: 40px;
      }

      .contact header{
                margin-bottom: 40px !important;
      }

      .contact-address{
          br{
              display: none;
          }
          font-size: 12px;
      }



      .v-card__actions{
        padding: 5px 10px !important;
        max-width: 100%;;
      }
      ::v-deep .v-btn.v-btn--has-bg {
            span.v-btn__content{
                    font-size: 12px!important;
                    font-weight: 500!important;
            }
      }

}

::v-deep .v-input__slot::before  { border: none !important; }
</style>
